<app-filter [title]="'apartment.title'" [formConfig]="formFilterConfig" (onSearchChanges)="getData($event)"></app-filter>
<div class="m-3 bg-white box-child">
  <div class="p-3">
    <app-button class="me-2" [iconLeft]="'plus'" [type]="'primary'" [label]="'add' | translate" (clickButton)="add()"></app-button>
    <app-button [iconLeft]="'file-down'" [label]="'export' | translate" (clickButton)="export()"></app-button>
  </div>
  <app-table-dynamic [columnsConfig]="formConfig" [source]="source" (onEdit)="edit($event)" (onView)="view($event)" [total]="totalRows"
                     (onDelete)="delete($event)" [scroll]="{x: null, y: 'calc(100vh - 469px)'}" (onChangeSort)="onSort($event)"
                     (onChangePage)="onChangePage($event)" (onChangeSize)="onChangeSize($event)" [page]="filter['page'] ? filter['page'] : 1"></app-table-dynamic>
</div>
