<form [formGroup]="formGroup">
  <div class="bg-white p-3" style="border-radius: 4px">
    <div nz-row [nzGutter]="[16, 8]">
      <div nz-col nzSpan="8">
        <app-control formControlName="code" [controlConfig]="{title: 'apartment.code', hint: true, hintLabel: 'input-hint.code', type: 'text', required: true, regex: regex.code}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="apartment_type" [options]="listApartmentType" [controlConfig]="{title: 'apartment-type.label', type: 'dropdown', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="owner" [options]="listOwner" (getMoreData)="getMoreDataOwner($event)" (dropdownSearch)="filterDataOwner($event)" [controlConfig]="{title: 'owner.label', type: 'dropdownServer', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="co_owner" [options]="listCoOwner" (getMoreData)="getMoreDataCoOwner($event)" (dropdownSearch)="filterDataCoOwner($event)"  [controlConfig]="{title: 'apartment.co-owner', type: 'dropdownServer'}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="operation_type" [options]="listOperate" [controlConfig]="{title: 'operate.title', type: 'dropdown'}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="contract_no" [controlConfig]="{title: 'apartment.contract', type: 'text'}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="area" [controlConfig]="{title: 'apartment.area', type: 'text-number', options: listFloor}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="floor" [options]="listFloor" [controlConfig]="{title: 'floor.label', type: 'dropdown', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="building" [options]="listBuilding" [controlConfig]="{title: 'building.label', type: 'dropdown', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="project" [options]="listProject" [controlConfig]="{title: 'project.label', type: 'dropdown', required: true}"></app-control>
      </div>
      <div nz-col nzSpan="8">
        <app-control formControlName="status" [options]="listStatus" [controlConfig]="{title: 'status.title', type: 'status', required: true}"></app-control>
      </div>
    </div>
  </div>
  <div class="bg-white p-3 mt-2" style="border-radius: 4px" *ngIf="f['operation_type'].value === OperateType.Stay">
    <h4>{{ 'apartment.family-info' | translate }}</h4>
    <nz-table #table [nzData]="listExtraMember" [nzScroll]="listExtraMember.length > 5 ? {y: '10vh'} : {}" [nzTableLayout]="'fixed'"
              [nzBordered]="true" nzSize="small" [nzShowPagination]="false" class="mt-3">
      <thead>
      <tr>
        <th>{{ 'doc.fullName' | translate }}</th>
        <th>{{ 'doc.relation' | translate }}</th>
        <th>{{ 'doc.type' | translate }}</th>
        <th>{{ 'doc.code' | translate }}</th>
        <th>{{ 'doc.place' | translate }}</th>
        <th>{{ 'doc.date' | translate }}</th>
        <th>{{ 'doc.expire' | translate }}</th>
        <th style="width: 100px" *ngIf="mode !== itemMode.View">{{ 'action' | translate }}</th>
      </tr>
      </thead>
      <ng-container *ngIf="listExtraMember.length">
        <tbody>
        <tr *ngFor="let item of listExtraMember; let i = index">
          <td>
            <span *ngIf="mode === itemMode.View">{{ item['name'] }}</span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['name']" (modelValueChange)="item['name'] = $event" [controlConfig]="{label: 'doc.fullName', type: 'text'}"></app-model>
          </td>
          <td>
          <span *ngIf="mode === itemMode.View">
            <ng-container *ngFor="let itemRelation of listRelationship">
              <div *ngIf="itemRelation.value === item['relationship']">{{ itemRelation.text | translate }}</div>
            </ng-container>
          </span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['relationship']" (modelValueChange)="item['relationship'] = $event" [controlConfig]="{label: 'doc.relation', type: 'dropdown', options: listRelationship}"></app-model>
          </td>
          <td>
          <span *ngIf="mode === itemMode.View">
            <ng-container *ngFor="let itemType of listDocType">
              <div *ngIf="itemType.value === item['docs_type']">{{ itemType.text | translate }}</div>
            </ng-container>
          </span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['docs_type']" (modelValueChange)="item['docs_type'] = $event" [controlConfig]="{label: 'doc.type', type: 'dropdown', options: listDocType}"></app-model>
          </td>
          <td>
            <span *ngIf="mode === itemMode.View">{{ item['doc_no'] }}</span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['doc_no']" (modelValueChange)="item['doc_no'] = $event" [controlConfig]="{label: 'doc.code', type: 'text', max: 100}"></app-model>
          </td>
          <td>
            <span *ngIf="mode === itemMode.View">{{ item['issue_place'] }}</span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['issue_place']" (modelValueChange)="item['issue_place'] = $event" [controlConfig]="{label: 'doc.place', type: 'text', max: 250}"></app-model>
          </td>
          <td>
            <span *ngIf="mode === itemMode.View">{{ item['issue_date'] | date: 'dd/MM/yyyy' }}</span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['issue_date']" (modelValueChange)="item['issue_date'] = $event" [max]="item['expire_date'] || currentDate" [controlConfig]="{label: 'doc.date', type: 'date'}"></app-model>
          </td>
          <td>
            <span *ngIf="mode === itemMode.View">{{ item['expire_date'] | date: 'dd/MM/yyyy' }}</span>
            <app-model *ngIf="mode !== itemMode.View" [value]="item['expire_date']" (modelValueChange)="item['expire_date'] = $event" [min]="item['issue_date']" [controlConfig]="{label: 'doc.expire', type: 'date'}"></app-model>
          </td>
          <td class="text-center" *ngIf="mode !== itemMode.View">
            <app-button [disabled]="listExtraMember.length === 1" [type]="'confirm'" [confirmLabel]="'confirm-del' | translate" [label]="'trash-2'" (clickButton)="deleteMem(i)"></app-button>
          </td>
        </tr>
        </tbody>
      </ng-container>
    </nz-table>
    <ng-container *ngIf="!listExtraMember.length">
      <div style="width: 100%; text-align: center">
        <ord-grid-not-data></ord-grid-not-data>
      </div>
    </ng-container>
    <app-button *ngIf="mode !== itemMode.View" class="mt-3 d-block" (clickButton)="addMem()" [type]="'primary'" [iconLeft]="'plus'" [label]="'addChild' | translate"></app-button>
  </div>
  <div class="bg-white mt-2 p-3" style="border-radius: 4px" *ngIf="mode === itemMode.View">
    <div nz-row [nzGutter]="[16, 16]">
      <div nz-col nzSpan="12">
        <app-control formControlName="creator" [controlConfig]="{title: 'creator', type: 'text'}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="date_created" [controlConfig]="{title: 'created', type: 'date', showTime: true}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="updater" [controlConfig]="{title: 'updater', type: 'text'}"></app-control>
      </div>
      <div nz-col nzSpan="12">
        <app-control formControlName="date_updated" [controlConfig]="{title: 'updated', type: 'date', showTime: true}"></app-control>
      </div>
    </div>
  </div>
</form>
<div *nzModalTitle class="title-modal">{{ mode |translate }} {{ 'apartment.label' | translate }}</div>
<div *nzModalFooter>
  <ng-container *ngIf="mode == itemMode.View">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="edit()">{{ 'edit' | translate }}</button>
  </ng-container>
  <ng-container *ngIf="mode == itemMode.Edit || mode == itemMode.Add">
    <button nz-button class="btn-cancel-modal" (click)="destroyModal()">{{ 'close' | translate }}</button>
    <button nz-button nzType="primary" (click)="onSave()">{{ 'save' | translate }}</button>
  </ng-container>
</div>
