import {AfterViewInit, Component, inject} from '@angular/core';
import {
  DeleteStatus,
  ItemMode,
  ListDocType,
  ListRelationship,
  MasterDataStatus,
  OperateType,
  patchFormValue,
  REGEX
} from '../../../shared/shared.service';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import moment from 'moment/moment';
import {distinctUntilChanged} from 'rxjs';
import {ProxyDynamicService} from '../../../shared/service/proxy-dynamic.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-apartment-item',
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class ItemComponent implements AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  formConfig = [];
  itemMode = ItemMode;
  formGroup!: FormGroup;
  mode = ItemMode.Add;
  listApartmentType: any[] = [];
  listOwner: any[] = [];
  listCoOwner: any[] = [];
  listFloor: any[] = [];
  listProject: any[] = [];
  listBuilding: any[] = [];
  listStatus: any[] = [];
  listOperate: any[] = [];
  listRelationship = ListRelationship;
  listDocType = ListDocType;
  listExtraMember: any[] = [];
  listExtraMemberDelete: any[] = [];
  OperateType = OperateType;
  currentDate = moment().set({h: 0, m: 0, s: 0, ms: 0}).toDate();
  regex = REGEX;
  keywordOwner = '';
  keywordCoOwner = '';
  cancelSearchOwner = false;
  cancelSearchCoOwner = false;

  get f() {
    return this.formGroup.controls;
  }

  constructor(
    private modal: NzModalRef,
    private fb: FormBuilder,
    private service: ProxyDynamicService,
    private noti: NzNotificationService,
    private translate: TranslateService
  ) {
    this.formConfig = this.nzModalData.formConfig;
    this.listApartmentType = this.nzModalData.listApartmentType;
    this.listFloor = JSON.parse(JSON.stringify(this.nzModalData.listFloor));
    this.listProject = JSON.parse(JSON.stringify(this.nzModalData.listProject));
    this.listBuilding = JSON.parse(JSON.stringify(this.nzModalData.listBuilding));
    this.listStatus = this.nzModalData.listStatus;
    this.listOperate = this.nzModalData.listOperate;
    this.formGroup = this.fb.group({
      id: [undefined],
      code: [null, [Validators.required, Validators.pattern(this.regex.code)]],
      apartment_type: [null, Validators.required],
      owner: [null, Validators.required],
      co_owner: [null],
      operation_type: [null],
      contract_no: [null],
      area: [null],
      floor: [null, Validators.required],
      building: [null, Validators.required],
      project: [null, Validators.required],
      status: [MasterDataStatus.Active],
      user_created: [null],
      creator: [null],
      date_created: [null],
      user_updated: [null],
      updater: [null],
      date_updated: [null],
      family_member: [null],
    });
    this.f['status'].disable();
    this.f['operation_type'].valueChanges.subscribe((value: any) => {
      if (value === OperateType.Stay && this.mode === this.itemMode.Add) {
        this.listExtraMember.push({
          name: '',
          relationship: null,
          docs_type: null,
          doc_no: '',
          issue_place: '',
          issue_date: null,
          expire_date: null
        })
      }
    });
    this.f['floor'].valueChanges.subscribe((value: any) => {
      const obj = this.listFloor.find((r: any) => r.id === value);
      this.f['building'].setValue(obj ? obj.building : null, {emitEvent: false});
      this.f['project'].setValue(obj ? obj.project : null, {emitEvent: false});
    })
    this.f['building'].valueChanges.subscribe((value: any) => {
      if (value) {
        const obj = this.listBuilding.find((r: any) => r.id === value);
        this.listFloor = obj ? JSON.parse(JSON.stringify(this.nzModalData.listFloor)).filter((r: any) => r.building === obj.id) : [];
        const objFloor = this.listFloor.length ? this.listFloor.find((r: any) => r.building === obj.id) : null;
        this.f['project'].setValue(obj ? obj.project : null, {emitEvent: false});
        this.f['floor'].setValue(objFloor ? objFloor.id : null, {emitEvent: false});
      } else {
        this.listFloor = JSON.parse(JSON.stringify(this.nzModalData.listFloor));
      }
    })
    this.f['project'].valueChanges.subscribe((value: any) => {
      if (value) {
        this.listBuilding = JSON.parse(JSON.stringify(this.nzModalData.listBuilding)).filter((r: any) => r.project === value);
        const objBuilding = this.listBuilding.length ? this.listBuilding.find((r: any) => r.project === value) : null;
        this.listFloor = objBuilding ? JSON.parse(JSON.stringify(this.nzModalData.listFloor)).filter((r: any) => r.building === objBuilding.id) : [];
        const objFloor = this.listFloor.length ? this.listFloor.find((r: any) => r.building === objBuilding.id) : null;
        this.f['building'].setValue(objBuilding ? objBuilding.id : null, {emitEvent: false});
        this.f['floor'].setValue(objFloor ? objFloor.id : null, {emitEvent: false});
      } else {
        this.listBuilding = JSON.parse(JSON.stringify(this.nzModalData.listBuilding));
        this.listFloor = JSON.parse(JSON.stringify(this.nzModalData.listFloor));
      }
    })
    this.f['owner'].valueChanges.subscribe((value: any) => {
      if (value) {
        this.listCoOwner = JSON.parse(JSON.stringify(this.nzModalData.listOwner)).filter((r: any) => r.id !== value);
      } else {
        this.listCoOwner = JSON.parse(JSON.stringify(this.nzModalData.listOwner));
      }
    })
    this.f['co_owner'].valueChanges.subscribe((value: any) => {
      if (value) {
        this.listOwner = JSON.parse(JSON.stringify(this.nzModalData.listOwner)).filter((r: any) => r.id !== value);
      } else {
        this.listOwner = JSON.parse(JSON.stringify(this.nzModalData.listOwner));
      }
    })
  }

  ngAfterViewInit() {
    this.mode = this.nzModalData.mode;
    if (this.nzModalData.mode !== ItemMode.Add) {
      if (this.nzModalData.data.owner) {
        this.keywordOwner = this.nzModalData.data.owner.name;
        this.getMoreDataOwner(false);
      }
      if (this.nzModalData.data.co_owner) {
        this.keywordCoOwner = this.nzModalData.data.co_owner.name;
        this.getMoreDataCoOwner(false);
      }
    }
    if (this.nzModalData.mode === ItemMode.View) {
      patchFormValue(this.formGroup, this.nzModalData.data);
      this.f['owner'].setValue(this.nzModalData.data.owner['id'] ? this.nzModalData.data.owner['id'] : this.nzModalData.data.owner, {emitEvent: false})
      this.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null, {emitEvent: false})
      this.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null, {emitEvent: false})
      this.listExtraMember = JSON.parse(JSON.stringify(this.nzModalData.data.family_member));
      this.formGroup.disable({emitEvent: false});
    } else {
      if (this.nzModalData.mode === ItemMode.Edit) {
        patchFormValue(this.formGroup, this.nzModalData.data);
        this.f['owner'].setValue(this.nzModalData.data.owner['id'] ? this.nzModalData.data.owner['id'] : this.nzModalData.data.owner, {emitEvent: false})
        this.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null, {emitEvent: false})
        this.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null, {emitEvent: false})
        this.listExtraMember = JSON.parse(JSON.stringify(this.nzModalData.data.family_member));
        this.formGroup.enable({emitEvent: false});
      }
    }
  }

  edit() {
    this.mode = ItemMode.Edit;
    patchFormValue(this.formGroup, this.nzModalData.data);
    this.f['owner'].setValue(this.nzModalData.data.owner['id'] ? this.nzModalData.data.owner['id'] : this.nzModalData.data.owner, {emitEvent: false})
    this.f['date_created'].setValue(this.nzModalData.data.date_created ? moment(this.nzModalData.data.date_created, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null, {emitEvent: false})
    this.f['date_updated'].setValue(this.nzModalData.data.date_updated ? moment(this.nzModalData.data.date_updated, "YYYY-MM-DDTHH:mm:ss.SSSSZ").toDate() : null, {emitEvent: false})
    this.formGroup.enable({emitEvent: false});
  }

  destroyModal() {
    this.modal.destroy()
  }

  onSave() {
    if (this.formGroup.valid) {
      this.f['family_member'].setValue(this.listExtraMember.filter(r => r.name || r.relationship ||
        r.doc_type || r.doc_no || r.issue_place || r.issue_date || r.expire_date));
      const data = this.formGroup.getRawValue();
      data['family_member'].map((item: any) => {
        item.id = item.id ? item.id : undefined;
        item.issue_date = moment(item.issue_date).format('YYYY-MM-DD')
        item.expire_date = moment(item.expire_date).format('YYYY-MM-DD')
        return item;
      })
      data.creator = undefined;
      data.updater = undefined;
      data.date_created = undefined;
      data.date_updated = undefined;
      if (this.mode === ItemMode.Add) {
        data.id = undefined;
        this.service.createItemApartment(data).subscribe(res => {
          if (res) {
            this.modal.destroy(true)
          }
        }, (error: any) => {
          if (error.errors[0].extensions.code === "RECORD_NOT_UNIQUE") {
            this.noti.create('error', '', this.translate.instant('error.code-existed', {val: data.code}));
          } else {
            this.service.handleError(error)
          }
        });
      }
      if (this.mode === ItemMode.Edit) {
        data.user_created = data.user_created?.id;
        data.user_updated = data.user_updated?.id || null;
        data['family_member'].map((item: any) => {
          item.id = item.id ? item.id : undefined;
          item.issue_date = moment(item.issue_date).format('YYYY-MM-DD')
          item.expire_date = moment(item.expire_date).format('YYYY-MM-DD')
          return item;
        })
        this.service.updateItemApartment(data).subscribe(res => {
          this.modal.destroy(true)
        }, (error: any) => {
          this.service.handleError(error)
        });
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  addMem() {
    this.listExtraMember.push({
      name: '',
      relationship: null,
      doc_type: null,
      doc_code: null,
      issue_place: null,
      issue_date: null,
      expire_date: null
    });
  }

  deleteMem(index: number) {
    if (this.listExtraMember[index].id) {
      this.listExtraMember[index]['deleted'] = DeleteStatus.YES;
      this.listExtraMemberDelete.push(this.listExtraMember[index])
    }
    setTimeout(() => {
      this.listExtraMember.splice(index, 1);
    })
    setTimeout(() => {
      if (!this.listExtraMember.length) {
        this.addMem();
      }
    });
  }

  getMoreDataOwner(event: any) {
    if (!this.cancelSearchOwner) {
      const query = !!this.keywordOwner ? {
        '_or': [{'name': {'_icontains': this.keywordOwner}}, {'code': {'_icontains': this.keywordOwner}}],
        'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}
      } : {'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}}
      this.service.searchWithMeta('owner', {
        filter: query,
        limit: 100,
        page: !event ? 0 : Math.round(this.listOwner.length / 100),
      }).then((res: any) => {
        if (!event && !!this.keywordOwner) {
          this.listOwner = res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          });
        } else {
          this.listOwner = this.listOwner.concat(res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          }));
        }
        this.cancelSearchOwner = this.listOwner.length === Number(res.total);
      })
    }
  }

  filterDataOwner(event: any) {
    this.keywordOwner = event;
    this.cancelSearchOwner = false;
    if (event) {
      this.getMoreDataOwner(false);
    } else {
      this.listOwner = []
    }
  }

  getMoreDataCoOwner(event: any) {
    if (!this.cancelSearchCoOwner) {
      const query = !!this.keywordCoOwner ? {
        '_or': [{'name': {'_icontains': this.keywordCoOwner}}, {'code': {'_icontains': this.keywordCoOwner}}],
        'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}
      } : {'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}}
      this.service.searchWithMeta('owner', {
        filter: query,
        limit: 100,
        page: !event ? 0 : Math.round(this.listCoOwner.length / 100),
      }).then((res: any) => {
        if (!event && !!this.keywordCoOwner) {
          this.listCoOwner = res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          });
        } else {
          this.listCoOwner = this.listCoOwner.concat(res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          }));
        }
        this.cancelSearchCoOwner = this.listCoOwner.length === Number(res.total);
      })
    }
  }

  filterDataCoOwner(event: any) {
    this.keywordCoOwner = event;
    this.cancelSearchCoOwner = false;
    if (event) {
      this.getMoreDataCoOwner(false);
    } else {
      this.listCoOwner = []
    }
  }
}
